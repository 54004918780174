<template>
  <div>
    <div class="text-center">
      <img src="../assets/img/logo-white.png" width="200">
    </div>
    <div class="card bg-dark mt-2">
      <div class="card-body">
        {{ $t('intro') }}<br/><br/>
      </div>
    </div>
    <div class="card mt-3" v-if="!sent">
      <form class="card-body" @submit.prevent="requestInvite()">
        <div class="form-group">
          <label for="">{{ $t('name') }}</label>
          <input type="text" class="form-control" v-model="name">
        </div>
        <div class="form-group">
          <label for="">Email</label>
          <input type="email" class="form-control" v-model="email">
        </div>
        <div class="form-group my-2 mb-0">
          <button type="submit" class="btn btn-success w-100 mt-1" :disabled="sending">
            <span v-if="!sending">
              {{ $t('request_invite') }}
            </span>
            <i class="fa fa-spinner fa-spin" v-if="sending"></i>
          </button>
        </div>
      </form>
    </div>
    <div class="card mt-3">
      <div class="card-body">
        <router-link to="/chat" class="btn btn-primary w-100">
          {{ $t('i_have_code') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'HelloWorld',
  data () {
    return {
      apiUrl: 'https://lovegenius.ai/',
      email: '',
      name: '',
      sending: false,
      sent: false
    }
  },
  methods: {
    requestInvite: function () {
      if (!this.name) {
        return alert('Type your name')
      }

      if (!this.email) {
        return alert('Type your email')
      }

      this.sending = true
      axios.post(`${this.apiUrl}v1/api/request-invite`, { name: this.name, email: this.email }).then((response) => {
        alert('Thank you, your code has been sent to your email.')
        this.sent = true
        this.$router.push(`/setup?code=${response.data.code}`)
      }).catch((err) => {
        if (err.response && err.response.data.error) {
          if (err.response.data.error === 'already_registered') {
            alert('Your email is already registered')
            this.sent = true
          }
        }

        this.sending = false
      })
    }
  },
  created: function () {
    if (window.location.host.match(/localhost/)) {
      this.apiUrl = 'http://localhost:5000/'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
