<template>
  <div>
    <div v-show="!initialized">
      <div class="text-center">
        <img src="../assets/img/logo-white.png" width="200">
      </div>
      <div class="card bg-dark mt-2">
        <div class="card-body">
          {{ $t('intro') }}
        </div>
      </div>
      <div class="card border-primary mt-3">
        <div class="card-body text-center">
          <form class="form-group" @submit.prevent="join()">
            <label for="" class="h5 mb-2">Access Code</label>
            <input type="text" v-model="invitationCode" class="form-control">
            <button type="submit" class="btn w-100 btn-primary mt-2" :disabled="initializing">
              <span v-if="!initializing">{{ $t('start') }}</span>
              <i v-if="initializing" class="fa fa-spinner fa-spin"></i>
            </button>
          </form>
        </div>
      </div>
    </div>

    <div class="chat-container" v-show="initialized">
      <div class="card border-primary mb-3">
        <div class="card-header p-2 clearfix">
          <div class="float-start">
            <img class="avatar me-1" src="https://upload.wikimedia.org/wikipedia/commons/6/65/Amy_Jackson_as_female_humanoid_Robot_in_2.0_%28film%29.jpg">
            <span>{{ botName }}</span>
          </div>
          <div class="float-end">
            <button @click="resetChat()" type="button" class="btn btn-danger btn-sm">Reset</button>
          </div>
        </div>
        <div class="card-body p-2 messages" ref="messagesContainer">
          <div class="p-2" v-if="loadingChat">
            <i class="fa fa-spinner fa-spin me-1"></i> Initializing chat...
          </div>
          <div :class="{ 'text-end': message.role === 'user' }" class="my-2" v-for="(message, index) in messages" v-bind:key="`message_${index}`">
            <div :class="{ 'bg-light': message.role === 'assistant', 'bg-success': message.role === 'user' }" class="message-container text-black py-2 px-3 card-text">
              {{ replaceUsername(message.content) }}
              <div class="chat-time">
                {{ moment(message.createdAt).format('hh:mm A') }}
              </div>
            </div>
          </div>
          <div class="p-1" v-if="sending">
            {{ botName }} {{ $t('is_typing') }}
          </div>
        </div>
        <form class="card-footer p-2" @submit.prevent="sendMessage()">
          <div class="input-group">
            <input ref="input" v-model="text" type="text" class="form-control" placeholder="Type your message..." aria-label="Message" aria-describedby="button-addon2" autofocus>
            <button :disabled="sending || loadingChat" class="btn btn-primary" type="submit">
              <span v-if="!sending">{{ $t('send') }}</span>
              <i class="fa fa-spinner fa-spin" v-if="sending"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'ChatComponent',
  data () {
    return {
      apiUrl: 'https://lovegenius.ai/',
      initializing: false,
      invitationCode: '',
      initialized: false,
      loadingChat: true,
      sending: false,
      botName: '',
      username: '',
      messages: [],
      text: '',
      chat: {},
      moment: moment,
    }
  },
  methods: {
    resetChat: function () {
      if (window.confirm('Are you sure?')) {
        axios.delete(`${this.apiUrl}v1/api/chats/${this.chat._id}`).then(() => {
          this.$router.push(`/setup?code=${this.chat.code}`)
        })
      }
    },
    scrollAndFocus: function () {
      setTimeout(() => {
        if (!this.$refs.messagesContainer) return

        if (this.$refs.messagesContainer && this.$refs.messagesContainer.scrollHeight) {
          this.$refs.messagesContainer.scrollTop = this.$refs.messagesContainer.scrollHeight
        }

        this.$refs.input.focus()
      }, 100)
    },
    replaceUsername: function (message) {
      message = message || ''

      if (message.split(`${this.username}:`)[1]) {
        message = message.split(`${this.username}:`)[1]
      }

      if (message.split(`${this.botName}:`)[1]) {
        message = message.split(`${this.botName}:`)[1]
      }

      return message
    },
    join: function () {
      this.initializing = true

      axios.post(`${this.apiUrl}v1/api/init`, { code: this.invitationCode }).then(response => {
        if (response.data.createChat) {
          return this.$router.push('/setup?code=' + this.invitationCode)
        }

        this.chat = response.data.chat
        this.username = response.data.chat.username
        this.botName = response.data.chat.name
        this.initialized = true
        this.messages = response.data.history || []
        this.loadingChat = false
        this.initializing = false
        this.scrollAndFocus()
      }).catch(err => {
        this.initializing = false
        console.error(err.response)
      })
    },
    sendMessage: function () {
      if (this.sending) return

      if (!this.text || !this.text.trim()) {
        this.text = ''
        return
      }

      this.sending = true

      this.messages.push({
        role: 'user',
        content: this.text
      })

      this.scrollAndFocus()

      axios.post(`${this.apiUrl}v1/api/message`, { text: this.text }).then((response) => {
        this.sending = false
        this.messages.push(response.data.message)
        this.scrollAndFocus()
      })

      this.text = ''
    }
  },
  created: function () {
    axios.defaults.withCredentials = true

    if (window.location.host.match(/localhost/)) {
      this.apiUrl = 'http://localhost:5000/'
    }

    if (this.$route.query.code) {
      this.invitationCode = this.$route.query.code
      this.join()
    }
  },
  mounted: function () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.chat-time {
  position: absolute;
  background: white;
  font-size: 12px;
  border-radius: 3px;
  padding: 2px 4px;
  right: -7px;
  bottom: -9px;
  color: #676363;
}

.avatar {
    vertical-align: middle;
    width: 28px;
    height: 28px;
    border-radius: 50%;
 }

 .messages .card-body {
  display: inline-block !important;
 }

 .message-container {
  border-radius: 8px;
  display: inline-block;
  position: relative;
 }

 .messages {
  min-height: 150px;
  max-height: 600px;
  overflow-y: auto;
 }
</style>
