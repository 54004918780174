<template>
  <div>
    <div class="card">
      <div class="card-body p-0">
        <table class="table mb-0">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Code</th>
              <th>Date</th>
              <th>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(invite, index) in invites" v-bind:key="`invite_${index}`">
              <td>
                {{ invite.name || invite.email }}
              </td>
              <td>
                {{ invite.email }}
              </td>
              <td>
                {{ invite.code }}
              </td>
              <td>
                {{ moment(invite.createdAt).format('LL') }}
              </td>
              <td class="text-end">
                <button class="btn btn-success btn-sm" v-if="!invite.approved" type="button" @click="approve(invite)">Approve</button>
                <router-link v-if="invite.approved && invite.created" class="btn btn-sm btn-primary" :to="`/chat?code=${invite.code}`">Open Chat</router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'ChatComponent',
  data () {
    return {
      apiUrl: 'https://lovegenius.ai/',
      invites: [],
      moment
    }
  },
  methods: {
    approve: function(invite) {
      axios.put(`${this.apiUrl}v1/api/admin/invites/${invite._id}/approve`).then(() => {
        this.getData()
      })
    },
    getData: function () {
      axios.get(`${this.apiUrl}v1/api/admin/invites`).then(response => {
        this.invites = response.data
      })
    }
  },
  created: function () {
    if (window.location.host.match(/localhost/)) {
      this.apiUrl = 'http://localhost:5000/'
    }

    this.getData()
  }
}
</script>