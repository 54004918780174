export default {
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
  "non-binary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-Binary"])},
  "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin / Nationality"])},
  "born_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Born year"])},
  "years_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["years old"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "social_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Class"])},
  "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High"])},
  "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
  "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low"])},
  "level_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level of study"])},
  "without_studies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without studies"])},
  "elementary_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementary School"])},
  "middle_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle School"])},
  "high_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High School"])},
  "college": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College"])},
  "electronic_edm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic / EDM"])},
  "musical_taste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musical taste"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "interest_topics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest topics"])},
  "climate_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Climate change"])},
  "technology_innovation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology and Innovation"])},
  "politics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politic and government"])},
  "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health & Wellness"])},
  "economy_finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economy and Finance"])},
  "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
  "equal_rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender equality and human rights"])},
  "religion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religion and spirituality"])},
  "art_culture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arts and culture"])},
  "music_composition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music composition"])},
  "music_playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music playing"])},
  "sports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sports and recreation"])},
  "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environment and sustainability"])},
  "science": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Science and discoveries"])},
  "entretainment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entertainment and media"])},
  "anime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anime"])},
  "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History and cultural heritage"])},
  "security_crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secutiry and crime"])},
  "medical_tech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical and technological advances"])},
  "geopolitics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geopolitics and international relations"])},
  "sustainable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sustainable development"])},
  "software_engineering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software engineering"])},
  "standup_comedy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standup comedy"])},
  "movies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movies"])},
  "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
  "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing"])},
  "videogames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videogames"])},
  "dancing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dancing"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
  "select_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select country"])},
  "select_gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select gender"])},
  "architecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Architecture"])},
  "cooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooking"])},
  "fashion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fashion"])},
  "pets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pets and animals"])},
  "travel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel"])},
  "paranormal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paranormal"])},
  "aliens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aliens"])},
  "comics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comics"])},
  "fitness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitness"])},
  "martial_arts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martial arts"])},
  "soccer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soccer"])},
  "invitation_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation code"])},
  "your_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LoveGenius is an innovative AI-powered app that allows you to create and interact with the love of your life, tailored to your personal preferences. 💘🤖 With our cutting-edge technology, LoveGenius learns from your tastes, interests, and desires to provide you with unique and customized experiences. Immerse yourself in a virtual world where love and connection are at the heart of it all. Join LoveGenius and find the perfect spark to ignite your love life! 🔥😍"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "is_typing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is typing..."])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "i_have_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I already have a code"])},
  "request_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Invite"])}
}