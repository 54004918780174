export default {
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Género"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masculino"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femenino"])},
  "non-binary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No binario"])},
  "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origen / Nacionalidad"])},
  "born_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Año de nacimiento"])},
  "years_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["años"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "social_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clase social"])},
  "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alta"])},
  "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
  "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baja"])},
  "level_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel de estudio"])},
  "without_studies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin estudios"])},
  "elementary_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primaria"])},
  "middle_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secundaria"])},
  "high_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparatoria"])},
  "college": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Universidad"])},
  "electronic_edm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electrónica / EDM"])},
  "musical_taste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gusto musical"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "interest_topics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temas de interés"])},
  "climate_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio climático"])},
  "technology_innovation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tecnología e innovación"])},
  "politics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política y gobierno"])},
  "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salud y bienestar"])},
  "economy_finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economía y finanzas"])},
  "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educación"])},
  "equal_rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igualdad de género y derechos humanos"])},
  "religion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religión y espiritualidad"])},
  "art_culture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artes y cultura"])},
  "music_composition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composición musical"])},
  "music_playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interpretación musical"])},
  "sports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deportes y recreación"])},
  "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medio ambiente y sostenibilidad"])},
  "science": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciencia y descubrimientos"])},
  "entretainment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entretenimiento y medios"])},
  "anime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anime"])},
  "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia y patrimonio cultural"])},
  "security_crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguridad y delincuencia"])},
  "medical_tech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avances médicos y tecnológicos"])},
  "geopolitics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geopolítica y relaciones internacionales"])},
  "sustainable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollo sostenible"])},
  "software_engineering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingeniería de software"])},
  "standup_comedy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comedia en vivo"])},
  "movies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Películas"])},
  "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negocios"])},
  "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing"])},
  "videogames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videojuegos"])},
  "dancing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baile"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar"])},
  "select_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar país"])},
  "select_gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar género"])},
  "architecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquitectura"])},
  "cooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cocina"])},
  "fashion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moda"])},
  "pets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mascotas y animales"])},
  "travel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viajes"])},
  "paranormal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paranormal"])},
  "aliens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraterrestres"])},
  "comics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cómics"])},
  "fitness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acondicionamiento físico"])},
  "martial_arts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artes marciales"])},
  "soccer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fútbol"])},
  "invitation_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de invitación"])},
  "your_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu nombre"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
  "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LoveGenius es una aplicación innovadora impulsada por IA que le permite crear e interactuar con el amor de tu vida, adaptado a tus preferencias personales. 💘🤖 Con nuestra tecnología, LoveGenius aprende de tus gustos, intereses y deseos para brindarte experiencias únicas y personalizadas. Sumérgete en un mundo virtual donde el amor y la conexión están en el centro de todo. ¡Únete a LoveGenius y encuentra la chispa perfecta para encender tu vida amorosa! 🔥😍"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar"])},
  "is_typing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["está escribiendo..."])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
  "i_have_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya tengo un código"])},
  "request_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar invitación"])}
}