import { createApp } from 'vue'
import * as VueRouter from 'vue-router'
import App from './App.vue'
import ChatComponent from './components/ChatComponent.vue'
import SetupComponent from './components/SetupComponent.vue'
import HelloWorld from './components/HelloWorld.vue'
import AdminInvites from './components/AdminInvites.vue'
import i18n from './i18n'

const routes = [
  { path: '/', component: HelloWorld },
  { path: '/chat', component: ChatComponent },
  { path: '/setup', component: SetupComponent },
  { path: '/admin/invites', component: AdminInvites },
]

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes, // short for `routes: routes`
})

let app = createApp(App).use(i18n)
app.use(router)
app.mount('#app')
