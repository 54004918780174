<template>
  <div>
    <div class="card" v-if="creating">
      <div class="card-body">
        <i class="fa fa-spinner fa-spin me-1"></i> We are creating your character, please wait...
      </div>
    </div>
    <div class="chat-container" v-if="!creating">
      <div class="card border-primary mb-3 mb-3">
        <div class="card-header bg-dark text-white">Setup your genuine love.</div>
        <div class="card-body bg-light">
          <div class="form-group mt-2">
            <label for="" class="text-black">{{ $t('language') }}</label>
            <select class="form-control mt-1" v-model="lang" @change="changeLanguage()">
              <option value="es">{{ $t('spanish') }}</option>
              <option value="en">{{ $t('english') }}</option>
            </select>
          </div>
          <div class="form-group mt-2">
            <label for="" class="text-black">{{ $t('gender') }}</label>
            <select class="form-control mt-1" v-model="gender" @change="generateName()">
              <option value="">{{ $t('select_gender') }}</option>
              <option value="masculino">{{ $t('male') }}</option>
              <option value="femenino">{{ $t('female') }}</option>
              <option value="no binario">{{ $t('non-binary') }}</option>
            </select>
          </div>
          <div class="form-group mt-2">
            <label for="" class="text-black">{{ $t('origin') }}</label>
            <select class="form-control mt-1" v-model="country" @change="generateName()">
              <option value="">{{ $t('select_country') }}</option>
              <option value="Mexico">Mexico</option>
              <option value="Estados Unidos">United States</option>
              <option value="Alemania">Germany</option>
              <option value="Corea del Sur">South Korea</option>
              <option value="Japon">Japan</option>
              <option value="Brasil">Brasil</option>
              <option value="Canada">Canada</option>
              <option value="Puerto Rico">Puerto Rico</option>
              <option value="Colombia">Colombia</option>
              <option value="Reino Unido">United Kingdom</option>
              <option value="Argentina">Argentina</option>
              <option value="Francia">France</option>
              <option value="Italia">Italy</option>
              <option value="China">China</option>
              <option value="Australia">Australia</option>
            </select>
          </div>
          <div class="form-group mt-2">
            <label for="" class="text-black">{{ $t('born_year') }} ({{ moment().diff(moment().year(year), 'years', false) }} {{ $t('years_old') }})</label>
            <input type="number" class="form-control mt-1" :max="minYear" v-model="year">
          </div>
          <div class="form-group mt-2">
            <label for="" class="text-black">{{ $t('name') }}</label>
            <div class="input-group">
              <input type="text" class="form-control" :value="name" readonly>
              <button :disabled="!country && !gender" @click="generateName()" type="button" class="btn btn-primary">
                {{ $t('change') }}
              </button>
            </div>
          </div>
          <div class="form-group mt-2">
            <label for="" class="text-black">{{ $t('social_class') }}</label>
            <select class="form-control mt-1" v-model="socialClass">
              <option value="Alta">{{ $t('high') }}</option>
              <option value="Media">{{ $t('medium') }}</option>
              <option value="Baja">{{ $t('low') }}</option>
            </select>
          </div>
          <div class="form-group mt-2">
            <label for="" class="text-black">{{ $t('level_study') }}</label>
            <select class="form-control mt-1" v-model="studyLevel">
              <option value="Primaria">{{ $t('elementary_school') }}</option>
              <option value="Secundaria">{{ $t('middle_school') }}</option>
              <option value="Preparatoria">{{ $t('high_school') }}</option>
              <option value="Universidad">{{ $t('college') }}</option>
            </select>
          </div>
          <div class="form-group mt-2">
            <label for="" class="text-black">{{ $t('musical_taste') }}</label>
            <div class="input-group">
              <select class="form-control" v-model="genre">
                <option value="Regional Mexicano">Regional Mexican</option>
                <option value="Salsa">Salsa</option>
                <option value="Cumbia">Cumbia</option>
                <option value="Pop">Pop</option>
                <option value="Rock">Rock</option>
                <option value="Rock 80s">Rock 80s</option>
                <option value="Rock 90s">Rock 90s</option>
                <option value="Metal">Metal</option>
                <option value="Punk">Punk</option>
                <option value="K-Pop">K-Pop</option>
                <option value="Alternativo">Alternative</option>
                <option value="Indie">Indie</option>
                <option value="Hip Hop/Rap">Hip Hop/Rap</option>
                <option value="R&B/Soul">R&B/Soul</option>
                <option value="Electrónica/EDM">{{ $t('electronic_edm') }}</option>
                <option value="Dance 80s">Rock 80s</option>
                <option value="Dance 90s">Rock 90s</option>
                <option value="Trance">Trance</option>
                <option value="Techno">Techno</option>
                <option value="House">House</option>
                <option value="Psytrance">Psytrance</option>
                <option value="Reggaeton">Reggaeton</option>
                <option value="Reggae">Reggae</option>
                <option value="Ska">Ska</option>
                <option value="Country">Country</option>
                <option value="Jazz">Jazz</option>
                <option value="Blues">Blues</option>
                <option value="Clásica">Classic</option>
              </select>
              <button type="button" :disabled="!genre" @click="addGenre()" class="btn btn-primary">
                {{ $t('add') }}
              </button>
            </div>
            <div class="mt-1">
              <div class="p-2 badge bg-primary me-2" v-for="(genre, index) in genres" v-bind:key="`genre_${index}`">
                {{ genre }} <a @click="removeItem(genres, index)" href="javascript:void(0)" class="ms-1"><i class="fa fa-times"></i></a>
              </div>
            </div>
          </div>
          <div class="form-group mt-2">
            <label for="" class="text-black">{{ $t('interest_topics') }}</label>
            <div class="input-group">
              <select class="form-control" v-model="topic" ref="topicSelect">
                <option value="Videojuegos">{{ $t('videogames') }}</option>
                <option value="Cambio climático">{{ $t('climate_change') }}</option>
                <option value="Tecnología e innovación">{{ $t('technology_innovation') }}</option>
                <option value="Política y gobierno">{{ $t('politics') }}</option>
                <option value="Salud y bienestar">{{ $t('health') }}</option>
                <option value="Economía y finanzas">{{ $t('economy_finance') }}</option>
                <option value="Educación">{{ $t('education') }}</option>
                <option value="Igualdad de género y derechos humanos">{{ $t('equal_rights') }}</option>
                <option value="Religión y espiritualidad">{{ $t('religion') }}</option>
                <option value="Arte y cultura">{{ $t('art_culture') }}</option>
                <option value="Deporte y recreación">{{ $t('sports') }}</option>
                <option value="Medio ambiente y sostenibilidad">{{ $t('environment') }}</option>
                <option value="Ciencia y descubrimientos">{{ $t('science') }}</option>
                <option value="Entretenimiento y medios">{{ $t('entretainment') }}</option>
                <option value="Composicion musical">{{ $t('music_composition') }}</option>
                <option value="Tocar musica">{{ $t('music_playing') }}</option>
                <option value="Anime">{{ $t('anime') }}</option>
                <option value="Ingeniería en sofware">{{ $t('software_engineering') }}</option>
                <option value="Historia y patrimonio cultural">{{ $t('history') }}</option>
                <option value="Seguridad y crimen">{{ $t('security_crime') }}</option>
                <option value="Avances médicos y tecnológicos">{{ $t('medical_tech') }}</option>
                <option value="Geopolítica y relaciones internacionales">{{ $t('geopolitics') }}</option>
                <option value="Desarrollo sostenible">{{ $t('sustainable') }}</option>
                <option value="Comedia standup">{{ $t('standup_comedy') }}</option>
                <option value="Peliculas">{{ $t('movies') }}</option>
                <option value="Marketing">{{ $t('marketing') }}</option>
                <option value="Negocios">{{ $t('business') }}</option>
                <option value="Baile">{{ $t('dancing') }}</option>
                <option value="Arquitectura">{{ $t('architecture') }}</option>
                <option value="Cocina">{{ $t('cooking') }}</option>
                <option value="Moda">{{ $t('fashion') }}</option>
                <option value="Animales y mascotas">{{ $t('pets') }}</option>
                <option value="Viajes">{{ $t('travel') }}</option>
                <option value="Paranormal">{{ $t('paranormal') }}</option>
                <option value="Extraterrestres">{{ $t('aliens') }}</option>
                <option value="Comics">{{ $t('comics') }}</option>
                <option value="Fitness">{{ $t('fitness') }}</option>
                <option value="Artes marciales">{{ $t('martial_arts') }}</option>
                <option value="Futbol soccer">{{ $t('soccer') }}</option>
              </select>
              <button :disabled="!topic" type="button" @click="addTopic()" class="btn btn-primary">
                {{ $t('add') }}
              </button>
            </div>
            <div class="mt-1">
              <div class="p-2 badge bg-primary me-2" v-for="(topic, index) in topics" v-bind:key="`topic_${index}`">
                {{ getTopicTranslation(topic) }} <a @click="removeItem(topics, index)" href="javascript:void(0)" class="ms-1"><i class="fa fa-times"></i></a>
              </div>
            </div>
          </div>
          <div class="form-group mt-2">
            <label for="" class="text-black">{{ $t('invitation_code') }}</label>
            <input type="text" class="form-control mt-1" v-model="invitationCode">
          </div>
          <div class="form-group mt-2">
            <label for="" class="text-black">{{ $t('your_name') }}</label>
            <input type="text" class="form-control mt-1" v-model="username">
          </div>
        </div>
        <div class="card-footer p-2 text-end">
          <button class="btn btn-success" type="button" @click="createChat()">
            <span>{{ $t('save') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import _ from 'underscore'

export default {
  name: 'SetupComponent',
  data () {
    return {
      apiUrl: 'https://lovegenius.ai/',
      lang: localStorage.getItem('defaultLanguage') || 'en',
      creating: false,
      studyLevel: '',
      socialClass: '',
      invitationCode: '',
      country: '',
      name: '',
      gender: '',
      topic: '',
      genre: '',
      genres: [],
      username: '',
      topics: [],
      moment: moment,
      minYear: moment().subtract(21, 'years').year(),
      year: moment().subtract(21, 'years').year(),
    }
  },
  methods: {
    changeLanguage: function () {
      localStorage.setItem('defaultLanguage', this.lang)
      location.reload()
    },
    getTopicTranslation: function (topic) {
      return this.$refs.topicSelect && this.$refs.topicSelect.querySelector(`option[value="${topic}"]`).text
    },
    createChat: function () {
      if (!this.country) {
        alert('Country is missing')
        return
      }

      if (!this.name) {
        alert('Name is missing')
        return
      }

      if (!this.socialClass) {
        alert('Social class is missing')
        return
      }

      if (!this.gender) {
        alert('Genre is missing')
        return
      }

      if (!this.topics.length) {
        alert('Topics/interests are missing')
        return
      }

      if (!this.genres.length) {
        alert('Genre is missing')
        return
      }

      if (!this.invitationCode) {
        alert('Invitation code is missing')
        return
      }

      if (!this.username) {
        alert('Your name is missing')
        return
      }

      this.creating = true

      axios.post(`${this.apiUrl}v1/api/create-chat`, {
        country: this.country,
        name: this.name,
        gender: this.gender,
        topics: this.topics,
        musicGenres: this.genres,
        socialClass: this.socialClass,
        bornYear: this.year,
        username: this.username,
        studyLevel: this.studyLevel,
        code: this.invitationCode,
        lang: this.lang
      }).then(() => {
        this.creating = false
        this.$router.push('/chat?code=' + this.invitationCode)
      })
    },
    removeItem: function (array, index) {
      array.splice(index, 1)
    },
    addGenre: function () {
      if (!this.genre) return

      if (!_.contains(this.genres, this.genre)) {
        this.genres.push(this.genre)
      }
    },
    addTopic: function () {
      if (!this.topic) return

      if (!_.contains(this.topics, this.topic)) {
        this.topics.push(this.topic)
      }
    },
    generateName: function () {
      axios.get(`${this.apiUrl}v1/api/generate-name`, { params: { lang: this.lang, gender: this.gender, country: this.country } }).then(response => {
        if (response.data.name !== 'Name') {
          this.name = response.data.name
        }
      })
    }
  },
  created: function () {
    if (this.$route.query.code) {
      this.invitationCode = this.$route.query.code
    }

    if (window.location.host.match(/localhost/)) {
      this.apiUrl = 'http://localhost:5000/'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 13px);
}
</style>
