<template>
  <div class="p-2">
    <router-view></router-view>
    <div class="footer pt-4 text-end">
      Built with <span style="color: red">&hearts;</span> for you by <a target="_blank" href="https://instagram.com/agnostictrance">Gilberto Avalos</a>.
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
@import './assets/css/bootstrap.min.css';
.form-group {
  margin-bottom: 16px;
}

.form-group > label {
  margin-bottom: 6px;
}
</style>
